var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type == 2)?_c('div',{staticClass:"er-wei-ma"},[_c('div',{ref:"qrCodeUrl",staticClass:"qrcode"}),_vm._m(0)]):_vm._e(),(_vm.carousel)?_c('a-carousel',{attrs:{"arrows":""}},[_c('div',{staticClass:"custom-slick-arrow",staticStyle:{"left":"80px","zIndex":"1","transform":"scale(2.5)"},attrs:{"slot":"prevArrow"},slot:"prevArrow"},[_c('a-icon',{attrs:{"type":"left"}})],1),_c('div',{staticClass:"custom-slick-arrow",staticStyle:{"right":"80px","transform":"scale(2.5)"},attrs:{"slot":"nextArrow"},slot:"nextArrow"},[_c('a-icon',{attrs:{"type":"right"}})],1),_vm._l((_vm.result),function(item,index){return _c('div',{key:'content' + item.moduleId + index,staticClass:"content-wall"},[_c('div',{staticClass:"type-title"},[_c('span'),_vm._v(_vm._s(item[0].name)+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"box"},[_c('ul',_vm._l((item),function(el,i){return _c('li',{key:i,class:_vm.moduleId == null
                                ? ''
                                : _vm.moduleId == el.moduleId
                                ? 'fx-global-active-' + i + ' fx-global-active'
                                : 'fx-global',style:(el.moduleId ? 'background-color:' + _vm.bgcJson[i] : ''),on:{"click":function($event){el.childrenModules == null
                                ? _vm.linkToQuestions(el.moduleId)
                                : _vm.freshList(el.childrenModules, el.moduleName)}}},[(el.moduleId)?_c('div',{staticClass:"main-font"},[_c('div',{staticClass:"main-iconfont"},[_c('img',{attrs:{"src":require('../../../assets/img/reasonList/' +
                                            el.moduleId +
                                            '.png'),"alt":""}})]),_c('span',[_vm._v(_vm._s(el.moduleName))])]):_vm._e()])}),0)])])])})],2):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"er-wei-ma-font"},[_c('span',[_vm._v("扫描二维码下载")]),_c('span',[_vm._v("空白文书模板")])])
}]

export { render, staticRenderFns }