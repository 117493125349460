<template>
    <div>
        <div class="er-wei-ma" v-if="type == 2">
            <div class="qrcode" ref="qrCodeUrl"></div>
            <div class="er-wei-ma-font">
                <span>扫描二维码下载</span>
                <span>空白文书模板</span>
            </div>
        </div>
        <a-carousel arrows v-if="carousel">
            <div
                    slot="prevArrow"
                    class="custom-slick-arrow"
                    style="left: 80px;zIndex: 1;transform: scale(2.5);"
            >
                <a-icon type="left" />
            </div>
            <div slot="nextArrow" class="custom-slick-arrow" style="right: 80px;transform: scale(2.5);">
                <a-icon type="right" />
            </div>
            <div
                    v-for="(item, index) in result"
                    :key="'content' + item.moduleId + index"
                    class="content-wall"
            >
                <div class="type-title"> <span></span>{{ item[0].name }} </div>
                <div class="content">
                    <div class="box">
                        <ul>
                            <li
                                    v-for="(el, i) in item"
                                    @click="
                                el.childrenModules == null
                                    ? linkToQuestions(el.moduleId)
                                    : freshList(el.childrenModules, el.moduleName)
                            "
                                    :key="i"
                                    :class="
                                moduleId == null
                                    ? ''
                                    : moduleId == el.moduleId
                                    ? 'fx-global-active-' + i + ' fx-global-active'
                                    : 'fx-global'
                            "
                                    :style="el.moduleId ? 'background-color:' + bgcJson[i] : ''"
                            >
                                <div class="main-font" v-if="el.moduleId">
                                    <div class="main-iconfont">
                                        <img
                                                :src="
                                            require('../../../assets/img/reasonList/' +
                                                el.moduleId +
                                                '.png')
                                        "
                                                alt=""
                                        />
                                    </div>
                                    <span>{{ el.moduleName }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </a-carousel>
    </div>
</template>

<script>
    import _ from 'lodash'
    import QRCode from "qrcodejs2";

    export default {
        name: 'index',
        data() {
            return {
                bgcJson: [
                    '#587AC1',
                    '#209AA7',
                    '#358CF3',
                    '#168CC2',
                    '#2BAAE3',
                    '#4259D1',
                    '#52C1CD',
                    '#2870DD',
                ],
                moduleId: null,
                result: null,
                carousel: true,
                type: this.$route.query.type,
            }
        },
        watch: {
            '$store.state.smartInstruments': {
                handler(newValue, oldValue) {
                    if (this.type == 2) {
                        this.result = _.chunk(newValue, 8)
                        window.console.log(this.result)
                    }
                },
            },
            '$store.state.legalAdvice': {
                handler(newValue, oldValue) {
                    if (this.type == 1) {
                        this.result = _.chunk(newValue, 8)
                    }
                },
            },
            '$store.state.breadcrumb': {
                handler(newValue, oldValue) {
                    this.carousel = false
                    setTimeout(() => {
                        this.result = _.chunk(
                            this.type == 1
                                ? this.$store.state.legalAdvice
                                : this.$store.state.smartInstruments,
                            8
                        )
                        this.carousel = true
                    })
                },
            },
        },
        mounted() {
            this.$nextTick(()=>{
                this.creatQrCode();
            })
            if (this.$store.state.smartInstruments && this.type == 2) {
                this.result = _.chunk(this.$store.state.smartInstruments, 8)
            } else if (this.$store.state.legalAdvice && this.type == 1) {
                this.result = _.chunk(this.$store.state.legalAdvice, 8)
            } else if (this.type == 3) {
                this.result = [
                    [
                        {
                            moduleId: 'LegalFare',
                            moduleName: '诉讼费计算器',
                            name: '案件计算器',
                        },
                        {
                            moduleId: 'Damage',
                            moduleName: '人身损害赔偿计算器',
                            name: '案件计算器',
                        },
                        {
                            moduleId: 'InjuryOnTheJob',
                            moduleName: '工伤纠纷计算器',
                            name: '案件计算器',
                        },
                        {
                            moduleId: 'Traffic',
                            moduleName: '交通赔偿计算器',
                            name: '案件计算器',
                        },
                    ],
                ]
            }
            // let audio = document.getElementById('xuanze')
            // audio.play()
        },
        methods: {
            creatQrCode() {
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: window.location.protocol+'//'+window.location.host+'/#'+this.$route.fullPath.replace('Machine/ReasonList?type=2','MobileBlankTemplate'), // 需要转换为二维码的内容
                    width: 100,
                    height: 100,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })

            },
            //获取答卷
            linkToQuestions(moduleId) {
                this.moduleId = moduleId
                setTimeout(() => {
                    if (this.type == 3) {
                        this.$router.push({
                            name: moduleId,
                            query: {
                                type: '3',
                                moduleId: moduleId,
                            },
                        })
                    } else {
                        this.$router.push({
                            name: 'Question',
                            query: {
                                type: this.$route.query.type,
                                moduleId: moduleId,
                            },
                        })
                    }
                }, 1400)
            },
            freshList(childrenModules, moduleName) {
                this.result = []
                childrenModules.forEach((el) => {
                    el.name = moduleName
                })
                this.result[0] = childrenModules
            },
        },
    }
</script>

<style lang="less">
    .qrcode{
        display: inline-block;
        img {
            width: 1.13rem;
            height: 1.13rem;
            background-color: #fff; //设置白色背景色
            padding: .06rem; // 利用padding的特性，挤出白边
            box-sizing: border-box;
        }
    }
</style>

<style lang="less" type="text/less" scoped>
    .content {
        animation: show 0.5s ease-in-out forwards;
        width: 100vw;
        overflow: hidden;
        height: calc(100vh - 164px);
        display: flex;
        align-items: center;
    }

    .title {
        padding: 0.34rem 0 0.24rem;

        .title1 {
            color: #4d4d4d;
            font-size: 0.26rem;
            margin-bottom: 0;
            line-height: 0.4rem;
        }

        .title2 {
            color: #666666;
            font-size: 0.14rem;
            margin-bottom: 0;
        }
    }

    .box {
        overflow: hidden;
        height: 6.32rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        ul {
            height: 6.32rem;
            width: calc(100vw - 2.8rem);
            display: flex;
            flex-wrap: wrap;

            li {
                width: 3.76rem;
                height: 3rem;
                float: left;
                margin: 0.1rem 0.1rem;
                cursor: pointer;
                color: #666;
                font-size: 0.16rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url('../../../assets/img/reasonList/bgi.png');
                background-repeat: no-repeat;
                background-size: 100%;

                .main-font {
                    height: 100%;
                    width: 2.2rem;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-between;

                    .main-iconfont {
                        margin-top: 0.44rem;
                        width: 1.4rem;
                        height: 1.4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-image: url('../../../assets/img/reasonList/borderbgi.png');
                        background-repeat: no-repeat;
                        background-size: 100%;

                        img {
                            width: 0.6rem;
                            height: 0.6rem;
                        }
                    }

                    span {
                        flex: 1;
                        margin-top: 0.3rem;
                        font-size: 0.2rem;
                        color: #ffffff;
                        text-align: center;
                    }
                }

                &:hover {
                    i {
                        color: @primary-color;
                    }
                }
            }
        }
    }

    @keyframes show {
        from {
            transform: scale(0.4);
            opacity: 0;
        }
        to {
            transform: scale(1);
            opacity: 1;
        }
    }

    .ant-carousel .slick-slide {
        text-align: center;
        height: 1.6rem;
        line-height: 1.6rem;
        background: #364d79;
        overflow: hidden;
    }

    .ant-carousel .custom-slick-arrow {
        width: 0.25rem;
        height: 0.25rem;
        font-size: 0.25rem;
        color: #fff;
        background-color: rgba(31, 45, 61, 0.11);
        opacity: 0.3;
    }

    .ant-carousel .custom-slick-arrow:before {
        display: none;
    }

    .ant-carousel .custom-slick-arrow:hover {
        opacity: 0.5;
    }

    .ant-carousel .slick-slide h3 {
        color: #fff;
    }

    .type-title {
        font-size: 26px;
        color: #fff;
        width: calc(100vw - 3.8rem);
        line-height: 26px;
        display: flex;
        margin: 90px auto -110px;
        letter-spacing: 2px;

        span {
            display: inline-block;
            width: 6px;
            height: 26px;
            background: #1cd4bd;
            margin-right: 8px;
        }
    }

    .er-wei-ma{
        position: fixed;
        right: 0;
        top:1.8rem;

        .er-wei-ma-font{
            width: 1.13rem;
            height: .60rem;
            background: #358CF3;
            border-radius: 0px 0px 0px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: -.08rem;
            span{
                font-size: .15rem;
                font-weight: 400;
                color: #FFFFFF;
                line-height: .22rem;
            }
        }
    }
</style>
